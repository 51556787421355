:root {
  --text-color-primary: rgba(0, 0, 0, 1);
  --text-color-secondary: rgba(119, 119, 119, 1);
  --text-color-disabled: rgba(152, 169, 188, 1);
  --text-color-positive: var(--success);
  --text-color-danger: var(--monza);

  --btn-bg-color-primary: rgba(0, 97, 252, 1);
  --btn-bg-color-hover: rgba(255, 255, 255, 1);
  --btn-bg-color-disabled: rgba(152, 169, 188, 1);

  --btn-text-color-primary: rgba(255, 255, 255, 1);
  --btn-text-color-hover: rgba(0, 97, 252, 1);
  --btn-text-color-disabled: rgba(152, 169, 188, 1);

  --teal: #05b8f2;
  --black: rgba(0, 0, 0, 1);
  --brand-primary: var(--blue-ribbon);
  --brand-primary-opaque: rgba(0, 97, 251, 0.6);
  --blue-ribbon: rgba(0, 97, 251, 1);
  --blue-hover: rgba(31, 49, 108, 1);
  --blue-dark: rgba(7, 6, 71, 1);
  --granite-gray: rgba(105, 105, 105, 1);
  --atlantis: rgba(109, 210, 47, 1);
  --sonic-silver: rgba(119, 119, 119, 1);
  --gull-gray: rgba(152, 169, 188, 1);
  --gull-gray-translucent: rgba(152, 169, 188, 0.5);
  --gray: #9a9a9a;
  --quick-silver: rgba(165, 165, 165, 1);
  --pink-swan: rgba(187, 187, 187, 1);
  --monza: rgba(204, 17, 23, 1);
  --celeste: rgba(204, 204, 204, 1);
  --alto: rgba(221, 221, 221, 1);
  --corn: rgba(237, 191, 0, 1);
  --concrete: rgba(242, 242, 242, 1);
  --concrete-opaque: rgba(242, 242, 242, 0.7);
  --concrete-lighter: rgba(239, 239, 239, 1);
  --white: rgba(255, 255, 255, 1);
  --white-opaque: rgba(255, 255, 255, 0.7);
  --white-translucent: rgba(255, 255, 255, 0.4);
  --blue-grey: rgb(248, 250, 251);
  --blue-grey-darker: rgb(232, 234, 235);
  --dark-grey: rgb(87, 87, 87);

  /* Table Colors */
  --table-accepted-row: #e4f0e2;
  --table-rejected-row: #f5e2e1;

  /* Notification Toast Colors */
  --success: rgba(39, 168, 88, 1);
  --error: var(--monza);
  --warning: rgba(237, 191, 44, 1);
  --info: rgba(0, 97, 251, 1);

  --font-size-xxxs: 8px;
  --font-size-xxs: 10px;
  --line-height-xxxs: 4px;
  --line-height-xxs: 10px;
  --font-size-xs: 11px;
  --line-height-xs: 11px;
  --font-size-s: 12px;
  --line-height-s: 12px;
  --font-size-m: 14px;
  --line-height-m: 14px;
  --font-size-l: 16px;
  --line-height-l: 16px;
  --font-size-xl: 18px;
  --line-height-xl: 18px;
  --font-size-xxl: 20px;
  --line-height-xxl: 20px;
  --font-size-xxxl: 24px;
  --line-height-xxxl: 24px;
  --font-size-xxxxl: 32px;
  --line-height-xxxxl: 32px;

  --font-family-fontawesome-regular: 'FontAwesome-Regular';
  --font-family-fontawesome_5_pro-light: 'FontAwesome 5 Pro-Light';
  --font-family-lato: 'Lato';
  --font-family-poppins: 'Poppins';
  --font-family-rubik: 'Rubik';

  --box-shadow-card: 0px 5px 15px #0000000d;
  --box-shadow-card-hover: 0px 10px 20px #0000000d;
  --box-shadow-card-active: 0px 5px 13px #0000000d;
  --box-shadow-small: 0px 1px 4px #0000001f;
  --box-shadow-regular: 0px 3px 9px #0000001f;
  --box-shadow-no-bottom: 0px -6px 9px #0000001f;
  --box-shadow-right-aside: -6px 0px 16px #0000001f;
  --box-shadow-deep: 5px 5px 5px #00000077;

  --transition-duration-medium: 300ms;
  --transition-duration-snappy: 150ms;
  --transition-duration-slow: 450ms;

  --font-weight-thin: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-bolder: 800;

  --border-radius: 4px;

  --row-gap-xl: 36px;
  --row-gap-lg: 32px;
  --row-gap-md: 28px;
  --row-gap-sm: 20px;

  --modal-max-width: 100%;
  --header-height: 60px;

  --z-index-modal: 100;
  --z-index-mobile-menu: 200;
}
